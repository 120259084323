"use client";

import { FC, useEffect, useRef } from "react";
import { Database } from "../../Supabase/supabase.types";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Grid,
  Box,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Link from "next/link";

interface BlogCardProps {
  blog: Database["public"]["Functions"]["GetBlogs"]["Returns"][number];
  maxHeight?: number | null;
}

export const HorizontalBlogCard: FC<BlogCardProps> = ({ blog, maxHeight }) => {
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cardRef.current && maxHeight) {
      cardRef.current.style.height = `${maxHeight}px`;
    }
  }, [maxHeight]);

  return (
    <Link href={`/blogs/${blog.slug}`} style={{ textDecoration: "none" }}>
      <Card
        ref={cardRef}
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "row",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          borderRadius: "8px",
          transition: "transform 0.3s ease-in-out",
          "&:hover": {
            transform: "translateY(-5px)",
            boxShadow: "0 6px 12px rgba(0,0,0,0.15)",
          },
          height: "250px",
          width: "0.9"
        }}
      >
        <Grid container>
          <Grid item xs={5}>
            <CardMedia
              component="img"
              image={
                blog.bannerUrl ||
                "https://mui.com/static/images/cards/paella.jpg"
              }
              alt={blog.title}
              sx={{ objectFit: "cover", padding: "16px 16px" }}
            />
          </Grid>
          <Grid item xs={7} sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent
              sx={{
                flex: "1 0 auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {blog.title}
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                component="div"
                sx={{ flexGrow: 1 }}
              >
                {blog.summary}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <IconButton aria-label="View Details">
                  <ArrowForwardIcon />
                </IconButton>
              </Box>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Link>
  );
};
