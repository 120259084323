"use client";
import { FC, useState } from "react";
import { toast } from "react-toastify";
import { SubscribeUI } from "./SubscribeUI";
import { emailRegExp } from "../utils/emailValidationRegex";

export const Subscribe: FC = () => {
  const [email, setEmail] = useState("");

  const handleSubscribe = async () => {
    if (!email.match(emailRegExp)) {
      return toast.error("The email you provided is not valid.");
    }

    try {
      const response = await fetch("/api/addSubscriber", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const error = await response.json();
        toast.error(error.error);
        return;
      }

      toast.success("You have successfully subscribed to our newsletter.");

      setTimeout(() => {
        setEmail(""); // Clear email input after successful subscription
      }, 1800);
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <SubscribeUI
      title="Level Up Your .NET Skills"
      text={[
        "Every Thursday morning, you can look forward to a new tip, best practices and innovative ideas.",
        "Stay tuned for valuable insights to enhance your development skills each week!",
      ]}
      onSubscribe={handleSubscribe}
      email={email}
      setEmail={setEmail}
    />
  );
};
