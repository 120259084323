"use client";

import { FC } from "react";
import { Database } from "../../Supabase/supabase.types";
import Link from "next/link";

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  IconButton,
} from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward"; // Import ArrowForward icon

interface BlogCardProps {
  blog: Database["public"]["Functions"]["GetBlogs"]["Returns"][number];
}

export const BlogCard: FC<BlogCardProps> = ({ blog }) => {
  return (
    <Link href={`/blogs/${blog.slug}`} style={{ textDecoration: "none" }}>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          borderRadius: "8px",
          transition: "transform 0.3s ease-in-out",
          "&:hover": {
            transform: "translateY(-5px)",
            boxShadow: "0 6px 12px rgba(0,0,0,0.15)",
          },
        }}
      >
        <CardMedia
          component="img"
          height="194"
          image={
            blog.bannerUrl || "https://mui.com/static/images/cards/paella.jpg"
          }
        />

        <CardHeader
          title={
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {blog.title}
            </Typography>
          }
          sx={{ pb: 0 }}
        />
        <CardContent>
          <Typography variant="body1" color="text.secondary">
            {blog.summary}
          </Typography>
        </CardContent>

        <CardActions sx={{ justifyContent: "flex-end", mt: "auto" }}>
          <IconButton aria-label="View Details">
            <ArrowForwardIcon />
          </IconButton>
        </CardActions>
      </Card>
    </Link>
  );
};
