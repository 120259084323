"use client";

import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { BlogCard } from "../../shared/components/BlogCard";
import { HorizontalBlogCard } from "../../shared/components/HorizontalBlogCard";
import { Database } from "../../Supabase/supabase.types";
import useIsMobile from "../hooks/useIsMobile"; // Update the path as necessary
import { Subscribe } from "./Subscribe";

export type Blog = Database["public"]["Tables"]["blogs"]["Row"];

interface BlogListProps {
  blogs: Blog[];
}

const BlogList: React.FC<BlogListProps> = ({ blogs }) => { 
  const isMobile = useIsMobile();
  
  const [maxCardHeight, setMaxCardHeight] = useState<number | null>(null);
  const cardRefs = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    if (blogs && blogs.length > 0) {
      const heights = cardRefs.current.map((ref) => ref?.clientHeight || 0);
      const maxHeight = Math.max(...heights);
      setMaxCardHeight(maxHeight);
    }
  }, [blogs]);

  if(blogs.length === 0)
  {
    return (
      <Box alignSelf={"center"} mt={4}>
        <Typography color={"black"} variant="h5" mb={4}>
          Subscribe now and be the first to read my upcoming blog post!
        </Typography>
        <Subscribe />
      </Box>
    ); 
  }
  return (
    <Grid container direction="column" spacing={2}>
      {blogs?.map((blog: Blog) => (
        <Grid item xs={12} key={blog.id}>
          <div>
            {isMobile ? (
              <BlogCard blog={blog} />
            ) : (
              <HorizontalBlogCard blog={blog} maxHeight={maxCardHeight} />
            )}
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default BlogList;
